<template>
	<el-dialog
			top="0" class="commonDia updateMem" :close-on-click-modal="false"
			:visible.sync="dialogPark" :show-close="false"
			width="600px" :close-on-press-escape="false">
		<template #title>
			<h1 class="tit">{{ statusText }}停车场信息</h1>
		</template>
		<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="formPark">
			<div class="flex">
				<el-form-item label="停车场名称">
					<el-input v-model="formPark.parkingName" clearable placeholder="请输入停车场名称"></el-input>
				</el-form-item>
				<el-form-item label="车位总数">
					<el-input type="number" v-model="formPark.stallTotal" clearable placeholder="请输入车位总数"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="停车场地点">
				<el-input v-model="formPark.parkingAddress" clearable placeholder="请输入停车场地点"></el-input>
			</el-form-item>
			<el-form-item label="停车场闸口">
				<div class="gate flex">
					<div v-for="(item, index) in gateList" class="gateList flex" :key="index">
						<span class="tit">闸口{{ index + 1 }}</span>
						<div class="valueGate flex">
							<el-input :value="item.name"></el-input>
							<i class="el-icon-circle-close" @click="remove(item)"></i>
						</div>
					</div>
				</div>
				<div class="flex gateInput">
					<el-input v-model="gate" clearable :placeholder="'请输入闸口名称'"></el-input>
					<el-button class="addGate" @click="addGate">+</el-button>
				</div>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="resetForm">取 消</el-button>
			<el-button type="primary" @click="parkButton('form')">确 定</el-button>
		</div>
	</el-dialog>

</template>

<script>
import $ from "jquery";
import { editParkingInfo, addParkingInfo } from "@/api/api";

export default {
	name: "parkUpdate",
	props: {
		showEditPark: {
			type: Boolean,
			default: false
		},
		state: {
			type: String
		},
		parkInfo: {
			type: Object
		}
	},
	data() {
		return {
			dialogPark: this.showEditPark,
			statusText: this.state,
			formPark: {},
			gateList: [],
			gate: ""
		};
	},
	watch: {
		showEditPark: {
			handler(newval) {
				this.dialogPark = newval;
			}
		},
		state: {
			handler(newval) {
				this.statusText = newval;
			}
		},
		parkInfo: {
			handler(val) {
				if (val) {
					this.formPark = val
					this.gateList = this.formPark.gateList
					if (this.formPark.gateList == null) {
					    this.formPark.gateList = [];
					}
				} else {
					this.formPark = {
						parkingName: "",
						gateNum: "",
						gateList: [],
						parkingAddress: "",
						stallTotal: ""
					};
				}
			},
			deep: true
		}
	},
	mounted() {
	},
	methods: {
		resetForm() {
			this.$emit("openPark", { "flg": false });
		},
		addGate() {
			if ($.trim(this.gate).length === 0) {
				this.$message.warning("请输入内容");
				return;
			} else {
				let arr={
					name:this.gate
				}
				this.gateList.push(arr);
				this.gate = "";
			}
		},
		remove(item) {
			let index = this.gateList.indexOf(item);
			if (index !== -1) {
				this.gateList.splice(index, 1);
				this.$message.success("删除闸口成功");
			}
		},
		parkButton(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.gateList.length >0){
						this.formPark.gateList = this.gateList
					}else {
						this.formPark.gateList = []
					}
					if (this.statusText === "新增") {
						addParkingInfo(JSON.stringify(this.formPark)).then(res => {
							if (res.data.code == 200){
								this.$message.success("新增成功");
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(() => {
							this.$message.error("新增失败");
						});
					} else {
						editParkingInfo(JSON.stringify(this.formPark)).then(() => {
							this.$message.success("修改成功");
						}).catch(() => {
							this.$message.error("修改失败");
						});
					}
					setTimeout(() => {
						this.$emit("openPark", { "flg": false });
					}, 500);
				} else {
					return false;
				}
			});
		}
	}
};
</script>


<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
